/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card-container {
    width: min-content !important;
    height: fit-content !important;
  }
  .image-container img {
    width: 100px !important;
    height: 100px !important;
  }
  .card-title h3 {
    font-size: smaller;
  }
  .card-body {
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .image-container img {
    width: 150px !important;
    height: 150px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .image-container img {
    width: 150px !important;
    height: 150px !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .image-container img {
    width: 200px !important;
    height: 200px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .image-container img {
    width: 200px !important;
    height: 200px !important;
  }
}

.container {
  padding-bottom: 20px;
  min-width: 220px;
}
.card-body {
  padding: 0;
  padding-top: 10px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.card-container {
  width: 300px;
  border-radius: 1rem;
  background-color: khaki;
  overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  transition: 0.3s;
  animation: ease-in;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 1rem;
}

.card-container:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 15px 0px;
}

.image-container img {
  margin-top: 15px;
  border-radius: 1rem;
  overflow: hidden;
  height: 200px;
}

.card-content {
  margin: 0.5rem;
  margin-top: 0.5rem;
}

h3,
p {
  /* Resetting default value*/
  margin: 0;
  padding: 0;
}

.card-title {
  margin-bottom: 0.05rem;
}

.btn {
  justify-content: center;
}

div.btn button {
  background-color: gray;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  transition: 0.2s;
  margin: 0.5rem;
  text-transform: uppercase;
}
div.btn button a {
  color: khaki;
}
.btn button:hover {
  background-color: #4578fc;
}
