li button {
	background: none;
	border: none;
}
.dropdown-menu-left {
	right: 0;
	left: auto;
}
#mainBody {
	background-image: url("/src/shared/backgroundImage3.jpg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: 100% 100%;
	color: black;
}
#titleBody {
	font-family: "Moria";
}
#contentBody {
	font-family: "CaslonAntique";
}
#contentBody,
#titleBody {
	background-color: rgba(204, 182, 162, 0.342);
}
/* TimeLine CSS */
#timeline {
	background-color: ivory;
}
#timeLineTabs li {
	padding: 2px;
	transform: skew(-0.3rad);
	background: rgb(165, 164, 164);
}

#jumbotronTimline {
	background-color: darkslateblue;
}
/* Carousel CSS */
.carousel-item {
	height: 400px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.carousel-item {
		height: auto;
	}
	#mainBody {
		min-width: 500px;
	}

	#titleBody h1 {
		font-size: 1.6rem;
	}

	#contentBody,
	#titleBody {
		background-color: rgba(204, 182, 162, 0.342);
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
	.carousel-item {
		height: auto;
	}
	#mainBody {
		min-width: 597px;
	}
	#titleBody h1 {
		font-size: 1.9rem;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
