/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
	font-family: "Moria";
	src: local("Moria"), url("./fonts/Moria_DF.TTF") format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "CaslonAntique";
	src: local("CaslonAntique"),
		url("./fonts//CaslonAntique.ttf") format("truetype");
	font-weight: normal;
}
